

#foobar img {
    --s: 2.5rem; /* the size on the corner */
    --t: 0.4rem; /* the thickness of the border */
    --g: 0.75rem; /* the gap between the border and image */

    padding: calc(var(--g) + var(--t));
    outline: var(--t) solid #66a8ff;
    outline-offset: calc(-1 * var(--t));
    mask: conic-gradient(at var(--s) var(--s), #0000 75%, #000 0) 0 0 /
        calc(100% - var(--s)) calc(100% - var(--s)),
      linear-gradient(#000 0 0) content-box;
    border-radius: 2px;
  }